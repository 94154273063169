import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';

import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/oriental-rug-cleaning';

export const query = graphql`
  query orientalRugCleaningPageQuery {
    rugsImage: file(relativePath: { regex: "/we-clean-rugs.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 202) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

class OrientalRugCleaningPage extends Component {
  componentDidMount() {
    this.setVideoAspect();

    window.addEventListener('resize', this.setVideoAspect);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setVideoAspect);
  }

  getAspectAdjustedHeight = (aspect, width) => width / aspect;

  setVideoAspect = () => {
    const el = document.querySelector('iframe');
    const width = el.offsetWidth;
    const height = this.getAspectAdjustedHeight(1.33, width);
    el.style.height = `${height}px`;
  };

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <Wrapper>
          <h1 className="underlined">Oriental and Area Rug Cleaning</h1>
          <div style={{ marginBottom: '20px' }}>
            <span className="hidden-sm">
              <Img
                fixed={data.rugsImage.childImageSharp.fixed}
                alt="We clean rugs from around the world."
                style={{ float: 'left', marginRight: '20px' }}
              />
            </span>
            <p>
              <strong>
                Proper, Safe Cleaning of Specialty and Antique Oriental Carpets
                & Area Rugs From Around the World
              </strong>
            </p>
          </div>
          <div style={{ clear: 'both' }} />
          <p>
            <strong>Sci-Tech’s Oriental Rug Cleaning Studio</strong> uses modern
            technology and chemistry along with time-tested methods to clean
            your most delicate rugs.
          </p>
          <iframe
            title="rug-cleaning-video"
            style={{ width: '100%' }}
            src="https://www.youtube.com/embed/ldsa39CYSNg?feature=oembed"
            allowFullScreen=""
          />
          <p>
            <strong>Oriental rug cleaning</strong> has come a long way over the
            centuries, and we have further perfected the practice. Sci-Tech
            cares for the finest to the most basic rugs from around the world.
            We will pick your rug up and bring it to our special cleaning studio
            where it will receive our very thorough Cleaning System to get
            maximum soil and spot removal. Having your rug cleaned in our plant
            allows us to give it several treatments for a more thorough
            cleaning, controlled drying, and the use of various tools that are
            not available on location.
          </p>
          <p>
            As a testament to our skill and ability, we have earned the{' '}
            <Link to="certifications-and-associations">
              Certified Rug Specialist
            </Link>{' '}
            designation from the The Institute of Inspection, Cleaning, and
            Restoration Certifications. This prestigious certification is only
            given to professionals who undergo intensive training, including
            studying every aspect of Oriental and specialty rugs such as their
            history, cleaning maintenance, restoration and identification.
          </p>
          <p>
            The first principle of area rug cleaning is: It should not be done
            in the client’s home. There are many different types of rugs, each
            with inherent problems when cleaning or washing them.
          </p>
          <p>
            It is the wealth of knowledge in the minds of the Sci-Tech team that
            keeps your rug safe through the cleaning process.
          </p>
          <ul>
            <li>
              We make certain to ensure the proper care and custody of your
              family heirloom, Oriental or throw rug while it is in our
              facility.
            </li>
            <li>
              Our facility is set up properly for the process of washing and
              drying rugs.
            </li>
            <li>
              Our plant is secure with alarms and cameras to protect items in
              our custody.
            </li>
            <li>
              More importantly we carry the proper care, custody and process
              insurance to clean this type of floor covering… Many Do Not.
            </li>
          </ul>
          <h2>
            How To Waste Money and Ruin Your Rugs by Having them Cleaned In Your
            Home
          </h2>
          <p>
            Wool area rugs have a natural propensity to hide soil… vast amounts
            of soil. Going once over the top with a steam cleaner will make it
            look better… but it isn’t even close to being clean.
          </p>
          <p>
            In fact, 90% of the allergens, sand, dirt, dust, dander, dust mites
            and their droppings are still in the rug! Having a rug cleaned
            in-home may at first seem monetarily beneficial, however that would
            be the only benefit. The rug would not only be receiving improper
            care, but may even be harmed.
          </p>
          <ul>
            <li>
              Mostly, it is the lack of knowledge on the part of many cleaners.
              Truck mounted units run very hot water, in excess of 240 degrees.
              If not turned down, the use of water this hot can bleed colors,
              felt the wool and cause excessive shrinkage.
            </li>
            <li>
              The detergents used on synthetic wall to wall are too strong to be
              used on wool. It can bleed colors immediately or weaken the dyes
              so they run the next time the rug is cleaned, especially if not
              rinsed out properly.
            </li>
            <li>
              The critical drying step is left to you. Orientals are not wall to
              wall, it is a dense mat of entwined natural fibers that needs
              controlled drying. Prolonged drying on this type of material
              promotes microbial growth which is associated with dry-rot.
            </li>
            <li>Fringes on rugs cannot be cleaned in home.</li>
            <li>
              The Most Important Step is Skipped…”Dusting” or dry soil removal,
              the step that removes all the dry contaminants like the allergens,
              dander and dust mites. If dusting was done in the home it would
              completely destroy the air quality in the home. Dusting is an
              extremely critical step before real cleaning takes place.
            </li>
            <li>
              Damage to wood floors limits the use of proper rinsing and dusting
              techniques for the body of the rug.
            </li>
          </ul>
          <p>
            It is understood that sometimes circumstances dictate cleaning a rug
            “on-location,” (under a billiard table), but it should be a rare
            exception rather than the rule.
          </p>
          <p>
            This may seem like an inconvenience to a homeowner, however it is
            imperative for a proper cleaning to be done.
          </p>
        </Wrapper>
      </Layout>
    );
  }
}

export default OrientalRugCleaningPage;
