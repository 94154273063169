import Styled from 'styled-components';

import { baseMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  @media screen and (max-width: 600px) {
    .hidden-sm {
      display: none;
    }
  }
`;

export { Wrapper };
